/*
 * @Author: kongfanqiang
 * @Email: 1260470885@qq.com
 * @Date: 2020-06-04 10:28:19
 * @LastEditTime: 2020-06-04 17:00:03
 * @Description: api接口
 */
import http from './axios'
/**
 * @Description 获取动态详情
 * @dynamicsId 动态ID
 */
const getDetail = (articleId) => {
  return http.get('/card-api/articlelist/getArticleDetailForUser',{
    articleId
  })
}
const getDynamics = (dynamicsId) => {
  return http.get('/card-api/dynamicslist/getDetail',{
    dynamicsId
  })
}
/**
 * 获取名片详情
 * @param {String} cardId 名片ID
 * @param {Boolean} fromHome 是否是首页，默认false（统计使用）
 */
const getCard = ({cardId, fromHome = false}) => {
  return http.get('/card-api/cardlist/getDetail',{
    cardId,
    fromHome
  })
 }
export {
  getDetail,
  getDynamics,
  getCard
}