/*
 * @Author: kongfanqiang
 * @Email: 1260470885@qq.com
 * @Date: 2020-06-16 14:48:46
 * @LastEditTime: 2020-07-15 19:00:55
 * @Description: 公共方法
 */
/**
  * [通过参数名获取url中的参数值]
  * @param  {[string]} queryName [参数名]
  * @return {[string]}           [参数值]
  */
export function getQueryValue(queryName) {
  var reg = new RegExp("(^|&)" + queryName + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURI(r[2]);
  } else {
    return null;
  }
}