/*
 * @Author: kongfanqiang
 * @Email: 1260470885@qq.com
 * @Date: 2020-06-01 17:34:20
 * @LastEditTime: 2020-08-10 16:27:18
 * @Description: 
 */ 
import React, {Component} from 'react';
import {getDetail, getDynamics, getCard} from '../../api/api'
import Swiper from 'swiper'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {getQueryValue} from '../../comm/fn'
import 'swiper/css/swiper.css'
import './detail.css'
import friendUrl from './friendCircle.png'
import applet from './applet.png'
const wx = require('weixin-js-sdk');
class detail extends Component{
  constructor(props) {
    super(props);
    this.$http = React.$http
    this.state = {
      data: {},
      userInfo: false,
      isQr: false,
      swiperList: [],
      isCard: false,
      share: false,
      wechatState: false,
      isAlert: false
    }
  }
  componentDidMount() {
    this.$http.get('/auxiliary-api/wechat/jsapi/sign',{
      appId: 'wxfb41605266e022a5',
      url: window.location.href
    }).then(res => {
      if (res.data.code === 0) {
        const e = res.data.result
        wx.config({
          debug: false,
          appId: e.app_id, // 必填，公众号的唯一标识
          timestamp: e.timestamp, // 必填，生成签名的时间戳
          nonceStr: e.noncestr, // 必填，生成签名的随机串
          signature: e.signature,// 必填，签名
          jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData'], // 必填，需要使用的JS接口列表
          openTagList: ['wx-open-launch-weapp']
        });
      }
    })
    // source 100002（直接打开） 100000（朋友圈）  100001（微信好友）
    const id = this.props.match.params.id
    // 有路径参数的增加统计接口
    if (!this.props.match.params.isTrue) {
      let record = {
        dataId: id,
        source: getQueryValue('source'),
        contentType: 2 // 1名片 2动态/列表 3服务/列表
      }
      if (getQueryValue('source')) {
        record.source = getQueryValue('source')
      } else {
        record.source = '100002'
      }
      this.$http.get('/card-api/loginrecord/add', record)
    }
    
    // 判断是否是小程序环境
    const getEnv = new Promise((resolve) => {
      wx.miniProgram.getEnv((res) => {
        this.miniprogram = res.miniprogram
        resolve('')
        if (!this.miniprogram) {
          this.judgeWechat()
        }
      })
    })
    if (this.contentNode) {
      this.contentNode.addEventListener('touchmove', this.handleTouchMove, { passive: false });
    }
    if (this.contentNode2) {
      this.contentNode2.addEventListener('touchmove', this.handleTouchMove, { passive: false });
    }
    // 获取用户信息，判断是否注册名片
    const cardPromise = this.$http.get('/card-api/user/get').then(res => {
      if (res.data.code === 0) {
        this.setState({
          isCard: res.data.result.currentState === 2
        })
        return res.data.result.currentState === 2
      }
    })
    // 有参数 则是平台文章素材
    if (this.props.match.params.isTrue) {
      const getData = () => {
        getDetail(id).then(res => {
          if (res.data.code === 0) {
            document.title = res.data.result.title
            this.setState({
              data: res.data.result
            })
            this.setShare({
              title: res.data.result.title,
              imgUrl: res.data.result.imageKey
            })
          }
        })
      }
      cardPromise.then(isCard => {
        if (isCard) {
          const id = this.props.match.params.id
          const callback = (res) => {
            if (res.data.code === 0) {
              getEnv.then(() => {
                if (this.miniprogram) {
                  wx.miniProgram.redirectTo({
                    url: '/pages/detail/detail?scene=' + res.data.result
                  })
                } else {
                  this.props.history.replace(`/detail/${res.data.result}`)
                  window.location.reload()
                }
              })
            } else {
              getData()
            }
          }
          this.$http.get('/card-api/dynamicslist/repeatArticle',{
            articleId: id
          }).then(callback)
        } else {
          getData()
        }
      })
    // 否则是用户动态文章
    } else {
      getDynamics(id).then(res => {
        if (res.data.code === 0) {
          const result = res.data.result
          this.setShare({
            title: result.articleData.articleTitle,
            imgUrl: result.articleData.articleImg
          })
          getCard({cardId: result.cardId}).then(res => {
            if (res.data.code === 0) {
              this.check(res.data.result)
              this.setState({
                userInfo: res.data.result,
                cardUrl: `${res.data.result.cardId ? '?scene=cid_' + res.data.result.cardId + '&source=100003' : '?source=100003'}`
              },this.setTextHeignt)
              new Swiper ('.swiper-container', {
                loop: false, // 循环模式选项
                // 如果需要分页器
                pagination: {
                  el: '.swiper-pagination',
                },
                // 如果需要前进后退按钮
                navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }
              })
            }
          })
          let data = {
            title: result.articleData.articleTitle,
            content: result.articleData.articleContent,
            updateTime: result.putTime
          }
          document.title = data.title
          this.setState({
            data: data
          })
        }
      })
    }
  }
  judgeWechat = () => {
    const wechat = navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i) ;
    const judgewechat = wechat[1].split('.')
    if(judgewechat[0] >= 7 && judgewechat[1] >= 0 && judgewechat[2] >= 12){
      this.setState({
        wechatState: true
      })
    }
  }
  handleTouchMove = (event) => {
    event.preventDefault()
  }
  setShare({title, imgUrl}) {
    wx.miniProgram.postMessage({ data: {title} })
    let link = window.location.href
    wx.ready(() => { //需在用户可能点击分享按钮前就先调用
      let messLink = link
      if (!getQueryValue('source') && !this.props.match.params.isTrue) {
        // 用户文章分享到朋友圈url增加参数
        if (~link.indexOf('?')) {
          link += '&source=100000'
          messLink += '&source=100001'
        } else {
          link += '?source=100000'
          messLink += '?source=100001'
        }
      }
      // 分享给好友
      wx.updateAppMessageShareData({ 
        title, // 分享标题
        desc: messLink, // 分享描述
        link: messLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl // 分享图标
      })
      // 分享到朋友圈
      wx.updateTimelineShareData({ 
        title, // 分享标题
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl // 分享图标
      })
    });
  }
  setTextHeignt = () => {
    const height = this.demo ? this.demo.offsetHeight : 0
    const textHegint = this.intro ? this.intro.offsetHeight : 0
    if (textHegint/height > 3) {
      this.setState({
        isMour: true
      })
    }
  }
  // 免费更换名片
  setCard = () => {
    // 已注册名片
    if (this.state.isCard){
      // me
      if (this.state.userInfo.isSelf) {
        // this.setState({
        //   share: true
        // })
      } else {
      // noMe
        const id = this.props.match.params.id
        const callback = (res) => {
          if (res.data.code === 0) {
            if (this.miniprogram) {
              wx.miniProgram.redirectTo({
                url: '/pages/detail/detail?scene=' + res.data.result
              })
            } else {
              this.props.history.replace(`/detail/${res.data.result}`)
              window.location.reload()
            }
          }
        }
        if (this.props.match.params.isTrue) {
          this.$http.get('/card-api/dynamicslist/repeatArticle',{
            articleId: id
          }).then(callback)
        } else {
          this.$http.get('/card-api/dynamicslist/repeatDynamics',{
            dynamicsId: id
          }).then(callback)
        }
      }
    } else {
    // 未注册
      if (this.miniprogram) {
        this.setState({
          isAlert: true
        })
      } else {
        this.setState({
          isQr: true
        })
      }
    }
  }
  cancel = () => {
    this.setState({
      share: false
    })
  }
  toIndex = () => {
    if (this.miniprogram) {
      let cardUrl = this.state.cardUrl
      wx.miniProgram.reLaunch({
        url: `/pages/visitorIndex/visitorIndex${cardUrl}`
      })
    } else {
      this.setState({
        isQr: true
      })
    }
  }
  hideQr = () => {
    this.setState({
      isQr: false,
      isAlert: false
    })
  }
  qrClick = (e) => {
    return e.stopPropagation()
  }
  check = (data) => {
    data.introductionImgList.map((item, index) =>{
      let img_url = item+'?'+Date.now()
      let img = new Image();
      // 改变图片的src
      img.src = img_url;
      let set
      let imgWh = () => {
        if (img.width) {
          clearInterval(set)
          let swiperList = this.state.swiperList
          if (img.width/img.height > 351/197) {
            swiperList[index] = {
              height: '100%'
            }
          } else {
            swiperList[index] = {
              width: '100%'
            }
          }
          this.setState({
            swiperList
          })
        }
      }
      set = setInterval(imgWh,60);
      // 加载完成获取宽高
      img.onload = imgWh
    })
  }
  copy = () => {
    if (this.state.userInfo.wechatId) {
      alert('复制成功')
    }
  }
  createWeAppHtml = () => {
    let cardUrl = this.state.cardUrl
    const w = (document.documentElement.clientWidth/750)*100;
    const com_src = 'https://images.lvzheng.com/card_banner/'
    return { __html: `<wx-open-launch-weapp username="gh_093803b0b72f" path="/pages/visitorIndex/visitorIndex.html${cardUrl}">
      <template>
        <div class="div">
          <div style="
            height: ${.96 * w}px;
            width: ${.96 * w}px;
            background: hsl(0, 0%, 100%, .9);
            border-radius: 50%;
            text-align: center;
          "><img style="
            width: ${.8 * w}px;
            height: ${.8 * w}px;
            margin-top: ${.08 * w}px;
            display: inline-block;
          " src="${com_src}business_card_big.png" /></div>
          <p style="
            display: block;
            margin-top: ${.08 * w}px;
            font-size: ${.28 * w}px;
            text-align: center;
            color: #fff;
          ">看名片</p>
        </div>
      </template>
    </wx-open-launch-weapp>` }
  }
  createWeAppHtmlTop = (userInfo) => {
    let cardUrl = this.state.cardUrl
    const w = (document.documentElement.clientWidth/750)*100;
    return { __html: `<wx-open-launch-weapp username="gh_093803b0b72f" path="/pages/visitorIndex/visitorIndex.html${cardUrl}">
      <template>
        <img style="
          width: ${1.44 * w}px;
          height: ${1.44 * w}px;
          border-radius: 50%;
        " src="${userInfo.headImage}" />
      </template>
    </wx-open-launch-weapp>` }
  }
  createWeAppHtmlTop2 = () => {
    let cardUrl = this.state.cardUrl
    const w = (document.documentElement.clientWidth/750)*100;
    return { __html: `<wx-open-launch-weapp username="gh_093803b0b72f" path="/pages/visitorIndex/visitorIndex.html${cardUrl}">
      <template>
        <span style="
          font-size: ${.28 * w}px;
          line-height: ${.4 * w}px;
          color: #5671CE;
        ">更多信息，请访问我的个人站点</span><img src=${applet} style="
          width: ${.4 * w}px;
          height: ${.4 * w}px;
          margin-left: ${.13 * w}px;
          margin-right: ${.04 * w}px;
          vertical-align: top;
        " /><span style="
          color: #DCAA65;
          font-size: ${.28 * w}px;
          line-height: ${.4 * w}px;
        ">立即查看</span>
      </template>
    </wx-open-launch-weapp>` }
  }
  render() {
    const data = this.state.data
    const userInfo = this.state.userInfo
    const contentSty = {'paddingBottom': userInfo ? '.4rem' : this.props.match.params.isTrue ? '1.5rem' : '0rem'}
    const com_src = 'https://images.lvzheng.com/card_banner/'
    const detailSty = {'paddingBottom': !userInfo.isSelf && !this.props.match.params.isTrue ? '1.5rem' : ''}
    // 定时执行获取宽高
    return <section className="detail">
      <div className="demo" ref={node => this.demo = node}>测试</div>
      <header>
        <h2>{data.title}</h2>
        <div className="timeNum">
          {this.props.match.params.isTrue ? <a href="/list">律咔</a> : <span>{data.updateTime}</span> }
          {data.repeatNum && <span className="shareNumber">{data.repeatNum}次分享</span>}
        </div>
      </header>
      <section className="main" style={detailSty}>
        {userInfo && <div className="topUser clear">
          <div className="left">
            {this.state.wechatState ? <div dangerouslySetInnerHTML={this.createWeAppHtmlTop(userInfo)}></div> : 
            <img src={userInfo.headImage} onClick={this.toIndex} />}
            <a className="btn" href={`tel://${userInfo.mainTelephone}`}>联系TA</a>
          </div>
          <div className="right">
            <span className="name">{userInfo.realName}</span>
            <span className="job">{userInfo.companyInfo.job}</span>
            <span className="companyName">{userInfo.companyInfo.name}</span>
          </div>
        </div>}
        {userInfo && <div className="tipTo clear">{this.state.wechatState ? <div dangerouslySetInnerHTML={this.createWeAppHtmlTop2(applet)}></div> : 
          <div onClick={this.toIndex}><span>更多信息，请访问我的个人站点</span><img src={applet} /><span className="p">立即查看</span></div>}</div>}
        <div className="content" style={contentSty} dangerouslySetInnerHTML={{ __html:data.content}}></div>
        {userInfo && <div className="bottom">
          <img src={userInfo.headImage} />
          <div className="intro">
            <div className="mainTop">
              <p className="name">{userInfo.realName}</p>
              <div className="header">
                {this.state.wechatState ? 
                <div dangerouslySetInnerHTML={this.createWeAppHtml()}></div>
                :
                <div className="div" onClick={this.toIndex}>
                  <div><img src={`${com_src}business_card_big.png`} /></div>
                  <p>看名片</p>
                </div>}
                <a className="div" href={`tel://${userInfo.mainTelephone}`}>
                  <div><img src={`${com_src}iphone_big.png`} /></div>
                  <p>打电话</p>
                </a>
                <CopyToClipboard text={userInfo.wechatId} onCopy={this.copy}>
                  <div className="div">
                    <div><img src={`${com_src}wechat_white${!userInfo.wechatId ? '_un' : ''}.png`} /></div>
                    <p>加微信</p>
                  </div>
                </CopyToClipboard>
              </div>
            </div>
            <div className="swiper-container">
              <div className="swiper-wrapper">
                {userInfo.introductionImgList.map((item, index) =>{
                  return <div className="swiper-slide" key={item}>
                    <img src={item} style={this.state.swiperList[index]} />
                  </div>
                })}
              </div>
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
            </div>
            {userInfo.introduction && <div className="text">
              <div ref={ node => this.intro = node }>
                {userInfo.introduction}
              </div>
            </div>}
          </div>
          {this.state.isMour && <div className="mour" onClick={this.toIndex}>查看更多&gt;</div>}
        </div>}
        {/* <div className="button" onClick={this.setCard}>{userInfo.isSelf ? '分享' : '免费换成我的名片'}</div> */}
        {!userInfo.isSelf && <div className="button" onClick={this.setCard}>免费换成我的名片</div>}
      </section>
      <div className={`qrImg ${this.state.isQr ? 'active' : ''}`} onClick={this.hideQr} ref={ node => this.contentNode = node }>
        <div onClick={this.qrClick}>
          <div className="title">{this.state.isCard ? `${userInfo.realName}的名片码` : '您还未创建名片'}{!this.state.isCard && <p>暂时无法使用</p>}</div>
          <img src={this.state.isCard ? userInfo.qrCodeUrl : 'https://images.lvzheng.com/card_qr.jpg'}/>
          <div className="dec">律咔拓客名片，提升你的吸引力</div>
          <div className="bottom">{this.state.isCard ? '长按识别，立即查看TA的名片' : '长按识别，立即创建'}</div>
        </div>
      </div>
      <div className={`qrImg ${this.state.isAlert ? 'active' : ''}`} onClick={this.hideQr} ref={ node => this.contentNode = node }>
        <div onClick={this.qrClick} className="body">
          <p className="content">您还未创建个人名片<br/>请前往首页创建</p>
          <div className="footer">
            <div className="fBtn" onClick={this.hideQr}>再看看</div>
            <div className="fBtn" onClick={this.toIndex}>去创建</div>
          </div>
        </div>
      </div>
      <div className={`share ${this.state.share ? 'active' : ''}`} ref={ node => this.contentNode2 = node }>
        <div>
          <div className="line">
            <div onClick={this.sendMessage}>
              <img src={`${com_src}wechat2.png`} />
              <span>微信好友</span>
            </div>
            <div>
              <img src={friendUrl} alt=""/>
              <span>朋友圈</span>
            </div>
            <div>
              <img src={`${com_src}image_icon.png`} alt=""/>
              <span>图片海报</span>
            </div>
          </div>
          <div className="cancel" onClick={this.cancel}>取消</div>
        </div>
      </div>
    </section>
  }
}

export default detail;