/*
 * @Author: kongfanqiang
 * @Email: 1260470885@qq.com
 * @Date: 2020-06-01 16:21:04
 * @LastEditTime: 2020-07-17 11:42:21
 * @Description: 入口文件
 */ 
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import './style/reset.css'
import Routes from './routes'
import axios from './api/axios'
// import cookie from './comm/cookie'
window.$http = axios
React.$http = axios
// rem
var w = (document.documentElement.clientWidth/750)*100;
document.querySelector('html').style.fontSize = w + 'px';
// 判断是否有ticket 没有则微信授权
// 读取Cookie
const getCookie = (name) => {
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
      return unescape(arr[2]);
  else
      return null;
}
if (!getCookie('ticket')) {
  let redirect = encodeURIComponent(window.location.href)
  window.location.href = `${axios.defaults.baseURL}/card-api/user/h5/auth?redirect=${redirect}`
}
ReactDOM.render(
  <div>
    <Routes />
  </div>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
