/*
 * @Author: kongfanqiang
 * @Email: 1260470885@qq.com
 * @Date: 2020-06-16 15:35:32
 * @LastEditTime: 2020-07-21 18:14:16
 * @Description: 
 */ 
import './debug.css'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import React, {Component} from 'react';
const wx = require('weixin-js-sdk');

class page404 extends Component{
  constructor(props) {
    super(props);
    this.$http = React.$http
    this.state = {
      cookie: ''
    }
  }
  componentDidMount() {
    document.title = "debug页面"
    this.setState({
      cookie: document.cookie
    })
  }
  copy() {
    alert('cookie已复制')
  }
  clearCookie = () => {
    this.$http.get('/card-api/user/h5/logout').then(res => {
      if (res.data.code === 0) {
        alert('清除成功')      
      }
    })
  }
  render() {
    return <div className="debug">
      <CopyToClipboard text={this.state.cookie} onCopy={this.copy}>
        <div>复制cookie</div>
      </CopyToClipboard>
      <div onClick={this.clearCookie}>清除cookie</div>
    </div>
  }
}
export default page404;