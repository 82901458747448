/*
 * @Author: kongfanqiang
 * @Email: 1260470885@qq.com
 * @Date: 2020-06-01 16:42:50
 * @LastEditTime: 2020-06-16 15:42:05
 * @Description: 路由器
 */ 
import React from "react";
import { Route, Redirect, Switch, BrowserRouter, HashRouter } from "react-router-dom";
import home from './pages/material/material'
import detail from './pages/detail/detail'
import page404 from './pages/404/404'
import debug from './pages/debug/debug'
// const home = () => import('./pages/home/home')
export default () => (
  <BrowserRouter>
    <Switch>
      <Route path='/' render={() => <Redirect to='/list' />} exact />
      <Route path="/list" component={home} exact />
      <Route path="/detail/:id" component={detail} exact />
      <Route path="/detail/:id/:isTrue" component={detail} exact />
      <Route path="/404" component={page404} exact />
      <Route path="/debug" component={debug} exact />
    </Switch>
  </BrowserRouter>
)