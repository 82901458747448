/*
 * @Author: kongfanqiang
 * @Email: 1260470885@qq.com
 * @Date: 2020-06-01 16:21:04
 * @LastEditTime: 2020-07-10 14:50:49
 * @Description: 文章素材列表
 */ 
// import down from './Article_down.png'
import React from 'react';
import './material.css';
const wx = require('weixin-js-sdk');
class list extends React.Component {
  constructor(props) {
    super(props);
    this.$http = React.$http
    this.sortField = 1
    this.pageNum = 0
    this.cateId = 0
    this.state = {
      active: 0,
      cateList: [],
      articleList: [],
      // showSort: false,
      sortName: '默认排序',
      noLoad: false
    }
  }
  // 组件已经被渲染到 DOM 中后运行
  componentDidMount() {
    if (this.contentNode) {
      this.contentNode.addEventListener('scroll', this.onScrollHandle.bind(this));
    }
    wx.miniProgram.getEnv((res) => {
      this.miniprogram = res.miniprogram
    })
    document.title = '文章素材'
    this.$http.get('/card-api/catelist/getCateListForUser',{
      type: 3
    }).then(res => {
      if (res.data.code === 0){
        this.getList()
        this.setState({
          cateList: res.data.result
        })
      }
    })
  }
  componentWillUnmount() {
    if (this.contentNode) {
      this.contentNode.removeEventListener('scroll', this.onScrollHandle.bind(this));
    }
  }
  // 获取文章列表 cateId =0 all
  getList = () => {
    if (!this.state.noLoad) {
      this.$http.get('/card-api/articlelist/getArticleListForUser',{
        sortField: this.sortField, // 1默认-添加时间 2阅读量 3转发量
        cateId: this.cateId,
        pageSize: 10,
        pageNum: this.pageNum
      }).then(res => {
        if (res.data.code === 0){
          if (res.data.result < 10) {
            this.setState({
              noLoad: true
            })
          }
          let newList = [...this.state.articleList, ...res.data.result]
          if (this.pageNum === 0) {
            newList = res.data.result
          }
          this.setState({
            articleList: newList
          })
        }
      })
    }
  }
  switchType = (index = 0, item) => {
    console.log(item)
    this.contentNode.scrollTop = 0
    this.pageNum = 0
    this.setState({
      noLoad: false
    }, () => {
      this.cateId = item.cateId || 0
      this.getList()
    })
    this.setState({
      active: index
    })
  }
  toDetail(item) {
    // this.props.history.push(`/detail/${item.articleId}/true`)
    if (this.miniprogram) {
      // let id = encodeURIComponent(item.articleId + '/true')
      wx.miniProgram.navigateTo({
        url: '/pages/h5detail/detail?id=' + item.articleId + '/true'
      })
    } else {
      window.location = `/detail/${item.articleId}/true`
    }
  }
  // 展开排序类型
  // showSort = () => {
  //   this.setState({
  //     showSort: !this.state.showSort
  //   })
  // }
  // 排序选项点击事件
  sortClick(item) {
    this.contentNode.scrollTop = 0
    this.sortField = item.sortField
    this.pageNum = 0
    this.setState({
      noLoad: false,
      sortName: item.name
    },() => {
      this.getList()
    })
    // this.showSort()
  }
  // 滚动事件
  onScrollHandle(event) {
    const clientHeight = event.target.clientHeight
    const scrollHeight = event.target.scrollHeight
    const scrollTop = event.target.scrollTop
    const isBottom = (clientHeight + scrollTop > scrollHeight-20)
    // console.log(clientHeight,scrollTop, scrollHeight)
    // console.log('is bottom:' + isBottom)
    if (isBottom) {
      this.pageNum += 1
      this.getList()
    }
  }
  render() {
    const topItem = this.state.cateList.map((item, index) =>
      <span key={item.id+index} className={`topItem ${this.state.active-1 === index ? 'active' : ''}`} onClick={this.switchType.bind(this, index+1, item)}>{item.name}</span>
    )
    const sortType = [
      {
        name: '默认排序',
        sortField: 1
      },
      {
        name: '最多转发',
        sortField: 3
      },
      {
        name: '最多查看',
        sortField: 2
      }
    ].map(item =>
      <div className={this.state.sortName === item.name ? 'active' : ''} onClick={this.sortClick.bind(this, item)} key={item.sortField}>{item.name}</div>  
    )
    return (
      <section className="material box">
        <header>
          <span className={`topItem ${this.state.active === 0 ? 'active' : ''}`} onClick={this.switchType.bind(this, 0)}>推荐</span>
          {topItem}
        </header>
        <div className="condition">
          {sortType}
          {/* <div className="sort" onClick={this.showSort}>
            {this.state.sortName}
            <img src={down} />
          </div>
          <div className="sortType">
            {sortType}
          </div>
          <div className="isVip"></div> */}
        </div>
        <main className="article" ref={ node => this.contentNode = node }>
          {this.state.articleList.map(item =>
            <div className="listItem clear" key={item.id} onClick={this.toDetail.bind(this, item)}>
              <div className="left">
                <p>{item.title}</p>
                {item.repeatNum && <span className="shareNum"><span>{item.repeatNum}</span>次分享</span>}
              </div>
              <div className="image">
                <img src={item.imageKey}/>
              </div>
            </div>
          )}
          {this.state.noLoad && <div className="bottomText">我是有底线的</div>}
        </main>
      </section>
    )
  }
}
export default list;

