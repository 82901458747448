/*
 * @Author: kongfanqiang
 * @Email: 1260470885@qq.com
 * @Date: 2020-06-02 13:57:54
 * @LastEditTime: 2020-07-10 16:13:51
 * @Description: axios 拦截器
 */ 
import Axios from "axios"
// const baseURL = 'http://gateway.testlvzheng.com'
const baseURL = 'https://gateway.lvzheng.com'
// 创建实例时设置配置的默认值
let instance = Axios.create({
  baseURL,
  withCredentials: true,// 设置跨域携带cookie信息
  timeout: 500000
});
// 添加请求拦截器
instance.interceptors.request.use(config => {
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(response => {
  if (response.data.code === 400004) {
    window.location = '/404'
  } 
  if (response.data.code === 400002) {
    const redirect = window.location.href
    window.location.href = `${baseURL}/card-api/user/h5/auth?redirect=${redirect}`
  }
  if (response.data.code !== 0) {
    
  }
  return response
}, (error) => {
  return Promise.reject(error);
})
let get = function(url, params) {
  return instance.get(url, {
    params
  })
}
export default {
  ...instance,
  get
}