/*
 * @Author: kongfanqiang
 * @Email: 1260470885@qq.com
 * @Date: 2020-06-14 15:28:42
 * @LastEditTime: 2020-06-14 15:51:25
 * @Description: 
 */ 
import React, {Component} from 'react';
import './404.css'
class page404 extends Component{
  render() {
    return <div className="page404">
      <div>
        <img src="https://images.lvzheng.com/card_banner/404.png" />
        <p>哎呀！找不到页面了</p>
      </div>
    </div>
  }
}
export default page404;
